import {
  FIELD_KEYS,
  TRIGGER_FIELDS_CONFIG_MAP,
  PAYLOAD_MAP,
} from "./constants.js";

const NESTED_CONFIG_COUNT = 3; // Change this number to reflect the amount of SubSubConfiguration<index> fields
const NESTED_CONFIG_TEMPLATE = new Array(NESTED_CONFIG_COUNT).fill(NESTED_CONFIG_COUNT);

export const fillNestedConfigPayload = () => {
  return NESTED_CONFIG_TEMPLATE.map((_, i) => `${FIELD_KEYS.SubSubConfiguration}${i + 1}`);
};

export const fillNestedConfigModel = (defaultValue) => {
  return NESTED_CONFIG_TEMPLATE.reduce((acc, _, i) => {
    acc[`${FIELD_KEYS.SubSubConfiguration}${i + 1}`] = defaultValue;
    return acc;
  }, {});
};

export const getPartsFromCombinedValue = (combinedValue) => {
    const [id, configId] = combinedValue.split("/");
    return { id, configId };
};

export const getConfigKeys = (field) => {
    const { idKey, configIdKey, configValueKey, endpoint } = TRIGGER_FIELDS_CONFIG_MAP[field];
    return { idKey, configIdKey, configValueKey, endpoint };
};

export const getNextKey = (field, currentKey) => {
  const keys = PAYLOAD_MAP.get(field);
  const currentKeyIndex = keys.findIndex(el => el === currentKey);
  const nextKey = keys[currentKeyIndex + 1];
  return nextKey ?? null;
};

export const defineSubSubConfigCombinedValue = (item, index) => {
  const { SubConfiguration, SubSubConfiguration } = FIELD_KEYS;
  return index > 0 ? item[`${SubSubConfiguration}${index}`] : item[SubConfiguration];
};

export const defineSubSubConfigCurrentKey = (index) => {
  const { SubConfiguration, SubSubConfiguration } = FIELD_KEYS;
  return index > 0 ? `${SubSubConfiguration}${index}` : SubConfiguration;
};

export const mapObjectOptions = (items, field, key) => {
  const { idKey, configIdKey, configValueKey } = getConfigKeys(field);

  return items[key].map(el => ({
    id: `${el[idKey]}/${el[configIdKey]}`,
    label: el[configValueKey],
    value: `${el[idKey]}/${el[configIdKey]}`,
  })) ?? [];
};

export const mapArrayOptions = (items, field, key, index) => {
  const { idKey, configIdKey, configValueKey } = getConfigKeys(field);

  return items?.[index]?.[key]?.map(el => ({
    id: `${el?.[idKey]}/${el?.[configIdKey]}`,
    label: el?.[configValueKey],
    value: `${el?.[idKey]}/${el?.[configIdKey]}`,
  })) ?? [];
};
