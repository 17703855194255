<template>
    <div class="w-full">
        <div v-if="showLoader" class="flex justify-center items-center w-full h-full">
           <Loader  />
        </div>

        <template v-else>
        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Details</span>
            </template>

            <div class="flex gap-4 px-4">
                <FormulateInput
                    v-model="details.model.name"
                    validation="bail|required:trim|max:30,length|matches:/^[a-zA-Z0-9 ]*[a-zA-Z0-9]+[a-zA-Z0-9 ]*$/"
                    error-behavior="live"
                    class="flex-1"
                    label="Name"
                    placeholder="Trigger Name"
                    type="text"
                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                    :disabled="isLoading || isViewMode"
                    @validation="($event) => validateTriggerField($event, 'details', 'name')"
                />

                <FormulateInput
                    v-model="details.model.description"
                    class="flex-1"
                    label="Description"
                    placeholder="Trigger Description"
                    validation="bail|max:100,length"
                     error-behavior="live"
                    type="text"
                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                    :disabled="isLoading || isViewMode"
                    @validation="($event) => validateTriggerField($event, 'details', 'description')"
                />
            </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Trigger</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">If the following is true</div>
                <div class="flex gap-4 px-4">
                    <FormulateInput
                        v-model="events.model.type"
                        validation="required"
                        class="flex-1"
                        label="Trigger Type"
                        placeholder="Select Trigger Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'type')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', 'type')"
                    />

                    <FormulateInput
                        v-model="events.model.configuration"
                        validation="required"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'configuration')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', 'configuration')"
                    />

                    <FormulateInput
                        v-if="events.options.subConfiguration.length > 0"
                        v-model="events.model.subConfiguration"
                        validation="required"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'subConfiguration')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event)"
                    />
                </div>
            </div>
        </UISection>

        <UISection class="mb-4">
          <template #title>
            <span class="text-white-text">Condition</span>
          </template>

          <div class="rounded-xl border pb-4">
            <div class="flex items-center gap-2 px-4 py-2 bg-primary-100 rounded-t-xl text-sm">
                    <span>If</span>
                    <select class="w-14 h-6 rounded-md" v-model="conditions.operator">
                        <option
                            class="p-2 rounded-md"
                            v-for="operator in conditions.operators"
                            :value="operator.id"
                            :key="operator.id"
                        >
                           {{ operator.name }}
                        </option>
                    </select>
                    <span>of the following is true</span>
            </div>

              <template v-for="item, index in conditions.model" >
                <div  :key="getUUID()" class="flex items-center gap-4 w-full"><!-- eslint-disable-line -->
                <div  class="grid auto-cols-1 auto-rows-auto gap-2 flex-1">
                  <div class="flex gap-4 px-4">
                    <FormulateInput
                        v-model="item.type"
                        class="flex-1"
                        label="Condition Type"
                        placeholder="Select Condition Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'type', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', 'type')"
                    />

                    <FormulateInput
                        v-model="item.configuration"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'configuration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', 'configuration')"
                    />

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'conditions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', 'subConfiguration')"
                    />
                  </div>

                  <div
                    v-if="item.subConfiguration && getSubSubConfigsFromItem(item)?.length > 0"
                    class="flex gap-4 px-4"
                  >
                    <template v-for="subSubConfigKey, sscIdx in getSubSubConfigsFromItem(item)">
                      <FormulateInput
                        v-if="checkShowSubConfig(defineSubSubConfigCombinedValue(item, sscIdx), 'conditions', defineSubSubConfigCurrentKey(sscIdx), index)"
                        :key="`${index}-${sscIdx}`"
                        v-model="item[subSubConfigKey]"
                        class="flex-1"
                        :label="`Sub Configuration ${sscIdx + 1}`"
                        :placeholder="`Select Sub Configuration ${sscIdx + 1}`"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', subSubConfigKey, index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', subSubConfigKey)"
                      />
                    </template>
                  </div>
                </div>

                <TriggerControls
                  :model="conditions.model"
                  field="conditions"
                  :itemIndex="index"
                  :isLoading="isLoading"
                  :isViewMode="isViewMode"
                  @onRemoveItem="removeItem('conditions', index)"
                  @onAddItem="addItem('conditions')"
                />
                </div>
              </template>
          </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Action</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">Then perform the following.</div>
                <div v-for="item, index in actions.model" :key="getUUID()" class="flex gap-4"> <!-- eslint-disable-line -->
                <div class="flex gap-4 px-4 flex-1">
                    <FormulateInput
                        v-model="item.type"
                        validation="required"
                        class="flex-1"
                        label="Action Type"
                        placeholder="Select Action Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'type', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'actions', 'type')"
                    />

                    <FormulateInput
                        v-model="item.configuration"
                        validation="required"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'configuration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index,'actions', 'configuration')"
                    />

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'actions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        validation="required"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index)"
                    />
                </div>

                <TriggerControls
                    :model="actions.model"
                    field="actions"
                    :itemIndex="index"
                    :isLoading="isLoading"
                    :isViewMode="isViewMode"
                    @onRemoveItem="removeItem('actions', index)"
                    @onAddItem="addItem('actions')"
                />
                </div>
            </div>
        </UISection>

        <div class="mt-4 bg-white rounded-2xl h-20 shadow-md" v-if="!isViewMode">
            <div class="flex justify-end p-4">
                <Button
                    type="button"
                    :text="getTriggerId ? 'Update' : 'Save'"
                    :disabled="isLoading || disabledSubmit"
                    class="bg-primary"
                    @click="handleSubmit"
                />
            </div>
        </div>
        </template>
    </div>
</template>

<script>
import axios from "../../workflow-axios";
import { uniqWith, isEqual, clone } from "lodash";
import { uuid } from "vue-uuid";
import Button from "@shared/components/button";
import Loader from "@/components/loader";
import { TriggerControls } from "./components";
import {
    getPartsFromCombinedValue,
    getConfigKeys,
    getNextKey,
    defineSubSubConfigCombinedValue,
    defineSubSubConfigCurrentKey,
    mapObjectOptions,
    mapArrayOptions,
} from "./utils.js";
import {
  TRIGGER_FIELDS,
  TRIGGER_FIELDS_LIST,
  FIELD_KEYS,
  PAYLOAD_MAP,
  TRIGGER_FIELDS_ENDPOINTS,
  OPERATORS_LIST,
  DEFAULT_CONFIG_MODEL,
  DEFAULT_SUB_SUB_CONFIG_MODEL,
  DEFAULT_CONFIG_OPTIONS,
  DEFAULT_SUB_SUB_CONFIG_OPTIONS,
} from "./constants.js";

export default {
  name: "createTriggersAndActions",

  components: {
    Button,
    Loader,
    TriggerControls,
  },

    data() {
        return {
            details: {
                model: {
                    name: "",
                    description: "",
                },
                errors: {
                  name: false,
                  description: false,
                }
            },
            events: {
                model: {
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                },
                options: {
                    type: [],
                    configuration: [],
                    subConfiguration:[],
                },
                memo: {},
            },
            conditions: {
                model: [{
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                    subSubConfiguration1: "",
                    subSubConfiguration2: "",
                    subSubConfiguration3: "",
                }],
                options: [{
                    type: [],
                    configuration: [],
                    subConfiguration: [],
                    subSubConfiguration1: [],
                    subSubConfiguration2: [],
                    subSubConfiguration3: [],
                }],
                memo: {},
                operator: OPERATORS_LIST[0].id,
                operators: OPERATORS_LIST,
            },
            actions: {
                model: [{
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                }],
                options: [{
                    type: [],
                    configuration: [],
                    subConfiguration: [],
                }],
                memo: {},
            },
            isLoading: false,
            showLoader: true,
        }
    },

    async mounted() {
        try {
            if (!this.isViewMode) {
                const { Events, Conditions, Actions } = TRIGGER_FIELDS;
                const { Type } = FIELD_KEYS;

                const [events, conditions, actions] = await Promise.allSettled([
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Events] }),
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Conditions] }),
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Actions] })
                ]);

                this[Events].options[Type] = events?.value ?? [];
                this[Conditions].options[0][Type] = conditions?.value ?? [];
                this[Actions].options[0][Type] = actions?.value ?? [];
            }

            if (this.getTriggerId) {
                await this.getTriggerDetail();
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.showLoader = false;
        }
    },

    computed: {
        getAction() {
            return this.$route.params.action;
        },
        getType() {
            return this.$route.params.type;
        },
        getWorkflowId() {
            return this.$route.params?.workflowId;
        },
        getTriggerId() {
            return this.$route.params?.toolId;
        },
        selectConfig() {
            return { label: "name", trackBy: "id" };
        },
        isViewMode() {
            return this.$route.params.action === "view"
                || this.$route.name == "check admin details"
                || this.$route.name == "Package Tools";
        },
        isInvalidEvent() {
            for (const key in this.events.model) {
                const optionsItem = this.getItemPayload({ el: this.events.model, field: TRIGGER_FIELDS.Events, key });
                if (this.events.model[key] && !optionsItem) return true;
            }

            const baseFields = !this.events.model.type || !this.events.model.configuration;

            if (this.events.options.subConfiguration.length > 0) {
                return baseFields || !this.events.model.subConfiguration;
            }

            return baseFields;
        },
        isInvalidActions() {
            let result = false;

            for (const [index, row] of this.actions.model.entries()) {
                for (const key in row) {
                    if (!row[key].length && this.actions.options[index][key].length > 0) {
                        result = true;
                        break;
                    }
                }
            }

            return result;
        },
        disabledSubmit() {
            // Handle errors for null/undefined
            return !(this.details?.model?.name?.trim().length) 
                || Object.values(this.details?.errors || {}).some(e => e) 
                || this.isInvalidEvent
                || this.isInvalidActions;
        },
        submitMessage() {
          return this.getTriggerId ? "Trigger is successfully updated" : "New Trigger is successfully created";
        },
    },

    watch: {
        "events.model.type"() {
            this.events.model.configuration = "";
            this.events.model.subConfiguration = "";
            this.events.options.subConfiguration = [];
        },
        "events.model.configuration"() {
            this.events.model.subConfiguration = "";
        },
    },

    // TODO move API inteaction to service
    methods: {
      defineSubSubConfigCombinedValue,
      defineSubSubConfigCurrentKey,

        async getTriggerDetail() {
            try {
                const { data } =  await axios.get(`/workflow/${this.getType}/triggers`, {
                    params: {
                        workflow_id: this.getWorkflowId,
                        trigger_id: this.getTriggerId,
                    },
                });

                await this.initModel(data?.data?.[0]);
            } catch (err) {
                console.error(err);
            } finally {
                this.showLoader = false;
            }
        },

        async initModel(triggerDetail = {}) {
            for (const field in triggerDetail) {
                if (TRIGGER_FIELDS_LIST.includes(field)) {
                    const configModelKeys = PAYLOAD_MAP.get(field);
                    const { idKey, configIdKey } = getConfigKeys(field);

                    if (Array.isArray(this[field].model)) {
                        for (const [fieldsSetIndex, fieldsSetArr] of triggerDetail[field].entries()) {
                            if (fieldsSetIndex > 0) this.addItem(field);

                            for (const [idx, mk] of configModelKeys.entries()) {
                                const item = fieldsSetArr[idx];
                                if (!item) continue;

                                const combinedKey = `${item[idKey]}/${item[configIdKey]}`;

                                if (this.isViewMode) {
                                  this[field].options[fieldsSetIndex][mk] = [item];
                                }

                                await this.handleArrayInput(combinedKey, fieldsSetIndex, field, mk);
                                this[field].model[fieldsSetIndex][mk] = combinedKey;
                            }
                        }
                    } else {
                        for (const [idx, mk] of configModelKeys.entries()) {
                            const item = triggerDetail[field][idx];
                            if (!item) continue;
                            const combinedKey = `${item[idKey]}/${item[configIdKey]}`;

                            if (this.isViewMode) {
                              this[field].options[mk] = [item];
                            }

                            await this.handleObjectInput(combinedKey, field, mk);
                            this[field].model[mk] = combinedKey;
                        }
                    }
                }
            }

            const { name, description, conditions_operator } = triggerDetail;
            this.details.model.name = name;
            this.details.model.description = description ?? "";
            this.conditions.operator = conditions_operator ?? OPERATORS_LIST[0].id;
        },

        async fetchOptions({ endpoint }, params) {
            try {
                const { data } = await axios.get(`/workflow/${this.getType}/${endpoint}`, {
                    params: {
                        workflow_id: this.getWorkflowId,
                        ...params,
                    },
                });

                return uniqWith(data?.data ?? [], isEqual);
            } catch (err) { 
                console.error(err);
                this.isLoading = false;
            }
        },

        addItem(field) {
            const isConditionsField = field === TRIGGER_FIELDS.Conditions;

            const model = isConditionsField ? DEFAULT_SUB_SUB_CONFIG_MODEL : DEFAULT_CONFIG_MODEL;
            const options = isConditionsField ? DEFAULT_SUB_SUB_CONFIG_OPTIONS : DEFAULT_CONFIG_OPTIONS;

            this.$set(this[field].model, this[field].model.length, clone(model));

            this.$set(this[field].options, this[field].options.length, {
                type: this[field].options[0].type,
                ...options,
            });
        },

        removeItem(field, index) {
            this.$delete(this[field].model, index);
            this.$delete(this[field].options, index);
        },

        checkShowSubConfig(combinedValue, field, currentKey, index) {
            if (this.isViewMode) {
                const nextKey = getNextKey(field, currentKey)
                if (nextKey) {
                  const nextItem = this[field].options[index][nextKey] ?? {};
                  return Object.keys(nextItem).length > 0;
                }
            }

            return this.getArrayItemFromOptions(combinedValue, field, currentKey, index).has_child;
        },

        // TODO move to the utils
        getArrayItemFromOptions(combinedValue, field, currentKey, index) {
            const { idKey, configIdKey } = getConfigKeys(field);
            const { id, configId } = getPartsFromCombinedValue(combinedValue);

            return this[field].options[index][currentKey]
                ?.find(el => el[idKey] === id && el[configIdKey] === configId) ?? {};
        },

        async handleSubmit() {
            const includeConditions = this.checkAddConditionsToPayload();
            const includeDescription = this.details.model.description.trim().length > 0;

            const payload = {
                workflow_id: this.getWorkflowId,
                name: this.details.model.name.trim(),
                ...(includeDescription ? { description: this.details.model.description.trim() } : {}),
                conditions_operator: includeConditions ? this.conditions.operator : null,
                events: this.getPayloadForArrayOfObjects(TRIGGER_FIELDS.Events),
                conditions: includeConditions ? this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Conditions) : [],
                actions: this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Actions),
            };

            try {
                if (this.getTriggerId) {
                    await axios.put(`/workflow/${this.getType}/triggers`, {
                        ...payload,
                        trigger_id: this.getTriggerId,
                    });
                } else {
                  await axios.post(`/workflow/${this.getType}/triggers`, payload);
                }

                this.$toast.success(this.submitMessage);

                return this.$router.push({
                  name: "Workflow Tools",
                  params: {
                    workflowTool: "triggerAndActions",
                    workflowId: this.getWorkflowId,
                  },
                });
            } catch (err) {
                console.error(err.response.data.status_message);
                this.$toast.error(err.response.data.status_message);
            }
        },

        async handleObjectInput(combinedValue, field, currentKey) {
            if (!field && !currentKey) return;

            const nextKey = getNextKey(field, currentKey);
            if (!nextKey) return;

            if (this.isViewMode) return;

            const { idKey, configIdKey, endpoint } = getConfigKeys(field);
            const { id, configId } = getPartsFromCombinedValue(combinedValue);

            const item = this[field].options[currentKey]
                .find(el => el[idKey] === id && el[configIdKey] === configId);

            this.isLoading = true;

            if (item?.has_child) {
                const memoOpts = this[field].memo[combinedValue];

                if (!memoOpts?.length) {
                  const result = await this.fetchOptions({ endpoint }, {
                    [idKey]: item[idKey],
                    [configIdKey]: item[configIdKey]
                  });
                  this[field].options[nextKey] = result;
                  this.$set(this[field].memo, combinedValue, result);
                } else {
                  this[field].options[nextKey] = this[field].memo[combinedValue];
                }
            }

            this.isLoading = false;
        },

        async handleArrayInput(combinedValue, index, field, currentKey) {
            try {
                const nextKey = getNextKey(field, currentKey);
                if (!nextKey || this.isViewMode) return;

                const { idKey, configIdKey, endpoint } = getConfigKeys(field);
                const item = this.getArrayItemFromOptions(combinedValue, field, currentKey, index);

                this.isLoading = true;

                if (item?.has_child) {
                  const memoOpts = this[field].memo[combinedValue];

                  if (!memoOpts?.length) {
                     const result = await this.fetchOptions({ endpoint }, {
                      [idKey]: item[idKey],
                      [configIdKey]: item[configIdKey]
                    });
                    this[field].options[index][nextKey] = result;
                    this.$set(this[field].memo, combinedValue, result);
                  } else {
                    this[field].options[index][nextKey] = this[field].memo[combinedValue];
                  }
                }

                this.resetChildModel(field, currentKey, index);

                this.isLoading = false;
            } catch (err) {
                console.log("err", err);
                this.isLoading = false;
            }
         
        },

        resetChildModel(field, currentKey, index) {
          const { Type, Configuration, SubConfiguration, SubSubConfiguration } = FIELD_KEYS;
          const isConditionsField = field === TRIGGER_FIELDS.Conditions;
          const count = this.getSubSubConfigsFromItem(this[field].model[index]).length;

          switch (currentKey) {
            case Type:
              this[field].model[index][Configuration] = "";
              this[field].model[index][SubConfiguration] = "";
              this[field].options[index][SubConfiguration] = [];

              if (isConditionsField) {
                this.resetSubSubConfiguration(field, "model", index, count);
                this.resetSubSubConfiguration(field, "options", index, count);
              }
              break;

            case Configuration:
              this[field].model[index][SubConfiguration] = "";

              if (isConditionsField) {
                this.resetSubSubConfiguration(field, "model", index, count);
                this.resetSubSubConfiguration(field, "options", index, count);
              }
              break;

            case SubConfiguration:
              if (isConditionsField) {
                this.resetSubSubConfiguration(field, "model", index, count);
              }
              break;

            default:
              break;
          }

          // Reset only for the SubSubConfigs
          if (currentKey.includes(SubSubConfiguration) && isConditionsField) {
              const start = +currentKey[currentKey.length - 1];
              this.resetSubSubConfiguration(field, "model", index, count, start);
              this.resetSubSubConfiguration(field, "options", index, count, start + 1);
          }
      },

      resetSubSubConfiguration(field, dataField, index, count, start = 0) {
        const value = dataField === "model" ? "" : [];
        for (let i = start; i < count; i++) {
          this[field][dataField][index][`${FIELD_KEYS.SubSubConfiguration}${i + 1}`] = value;
        }
      },

        getMappedObjectOptions(field, key) {
          return mapObjectOptions(this[field].options, field, key);
        },

        getMappedArrayOptions(field, key, index) {
          return mapArrayOptions(this[field].options, field, key, index);
        },

        getSubSubConfigsFromItem(item) {
          return Object.keys(item).filter(key => key.includes(FIELD_KEYS.SubSubConfiguration));
        },

        // TODO move to the utils
        getPayloadForArrayOfObjects(field) {
            const keys = PAYLOAD_MAP.get(field);

            const result = keys
              .map(key => this.getItemPayload({ el: this[field].model, field, key }))
              .filter(item => item);

            return result;
        },

        // TODO move to the utils
        getPayloadForArrayOfArrays(field) {
            const keys = PAYLOAD_MAP.get(field);

            const result = this[field].model
                .map((el, index) => {
                    return keys
                        .map(key => this.getItemPayload({ el, field, key, index }))
                        .filter(item => item)
                })

            return result;
        },

        // TODO move to the utils
        getItemPayload({ el, field, key, index }) {
            const combinedValue = el[key];
            const { id, configId } = getPartsFromCombinedValue(combinedValue);
            const { idKey, configIdKey } = getConfigKeys(field);

            if (Array.isArray(this[field].options)) {
              return this[field].options?.[index]?.[key]
                ?.find(el => el?.[idKey] === id && el?.[configIdKey] === configId)
            }

            return this[field].options[key]
              .find(el => el[idKey] === id && el[configIdKey] === configId);
        },

        checkAddConditionsToPayload() {
            let result = true;

            // TODO Temporary workaround
            if (!this.conditions.model[0].type) return false;

            for (const [index, row] of this.conditions.model.entries()) {
                for (const key in row) {
                    if (!row[key].length && this.conditions.options?.[index]?.[key]?.length > 0) {
                        result = false;
                        break;
                    }
                }
            }

            return result;
        },

        validateTriggerField($event, field, currentKey) {
          this[field].errors[currentKey] = $event.hasErrors;
        },

        // TODO move to the utils
        getUUID() {
            return uuid.v4();
        },
    }
}
</script>
